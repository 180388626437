import React from 'react';
export type Mode = 'warning' | 'danger' | 'info' | 'success' | 'primary'| 'secondary' | 'light' | 'dark'  ;
function ErrorSummary({ errors, showEverything =false, mode ="warning" as Mode }) {

    return (((showEverything && Object.keys(errors).length>0) || errors?.summary!=null) && <div className={`alert alert-${mode}`}><ul>
        
        {
            showEverything && Object.entries(errors).map(([key, value], index) => {
                                // Check if the value is an array, iterate over it
                if (Array.isArray(value)) {
                  return value.map((error, subIndex) => (
                    <li key={`${key}-${subIndex}`}>{error}</li>
                  ));
                }
                // If the value is a string, display it directly
                if (typeof value === 'string') {
                  return <li key={key}>{value}</li>;
                }
                return null; // In case the value is not a string or array of strings
              })
        }
        {!showEverything && errors.summary?.map((error, index) => (
        <li key={index}>{error}</li>
    ))}</ul>
    </div>)

}


export default ErrorSummary;