import React, { useState, useEffect } from 'react';
import AddressRow from './AddressRow';
import Spinner from './Spinner';
import '../scss/AddressCompact.scss';
import {useApi} from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import ModalAddress from './ModalAddress';
import { PermissionsGate}  from './PermissionsGate';
import { HiOutlineMailOpen, HiOutlinePlus } from 'react-icons/hi';
import intl from 'react-intl-universal';

const AddressCompact = ({ addressesId, setAddressesId, editScopes, deleteScopes, disabled, allowOne = false, inputProvider = null, setInputProvider = null }) => {

    const api: Api = useApi();

    const [addresses, setAddresses] = useState([]);

    const [adressExist, setAddressExist] = useState(false);

	const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const [isAddressDetailOpen, setIsAddressDetailOpen] = useState(false);

    const fetchAddresses = async (addressesId: number[]) => {
        setLoading(true);
        const response = await api.addressApi.apiVversionAddressAddressesGet("1", addressesId, {}).then((response) => {
            if(response.data) {
                setAddresses(response.data?.data);
                setLoading(false);      
            };
            
        }).catch((error) => {
            if(error.response) {
                console.log("Data :" , error.response.data);
                console.log("Status :" + error.response.status);
            } else if(error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('address.toast.error.fetchAddresses'), body: error.message });
            setLoading(false);
        });
    };

    useEffect(() => {
        if (addressesId.length > 0 && addressesId !== null && addressesId[0] !== null) {
            fetchAddresses(addressesId);
        };
    }, [addressesId]);

    useEffect(() => {
        addresses.length > 0 ? setAddressExist(true) : setAddressExist(false);   
    }, [addresses]);

    const closeAddressDetail = () => setIsAddressDetailOpen(false);

    const handleAddressDetailOpen = () => {
        setIsAddressDetailOpen(true);
    };

    return (
        <>
            <div className="card-header">
                <h4 className="title"><HiOutlineMailOpen className='mb-1'/> {intl.get('addressCompact.header')}</h4>
            </div>
            {
                loading === true ?
                <Spinner small={true} /> :
                <div className="card-body">
                    <div className="table-responsive container-overflow">
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.address1')}</th>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.address2')}</th>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.country')}</th>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.stateProvince')}</th>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.city')}</th>
                                    <th scope="col" >{intl.get('addressCompact.table.thead.zipCode')}</th>
                                    <th scope="col" className='text-center'>{intl.get('addressCompact.table.thead.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    addresses?.map((e,i) => (
                                        <AddressRow 
                                        key={e.id}
                                        addressId={e.id}
                                        address1={e.address1}
                                        address2={e.address2}
                                        city={e.city}
                                        stateProvinceName={e.stateProvince?.name}
                                        zipCode={e.zipCode}
                                        countryName={e.country?.labelKey ? e.country.labelKey : e.country?.name}
                                        editScopes={editScopes}
                                        deleteScopes={deleteScopes}
                                        disabled={disabled}
                                        addresses={addresses}
                                        setAddresses={setAddresses}
                                        inputProvider={inputProvider}
                                        setInputProvider={setInputProvider}
                                        className={i%2>0?'':'odd'}
                                        />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                        <div className="text-center mt-2">
                            <button type="submit" className="btn btn-primary newAddress" disabled={disabled || (allowOne && adressExist)} onClick={handleAddressDetailOpen} ><HiOutlinePlus className='mb-1'/> {intl.get('addressCompact.newAddress.button')}</button>
                            <ModalAddress 
                                isOpen={isAddressDetailOpen} 
                                closeModal={closeAddressDetail} 
                                addressId={null} 
                                addresses={addresses} 
                                setAddresses={setAddresses} 
                                addressesId={addressesId} 
                                setAddressesId={setAddressesId} 
                                inputProvider={inputProvider} 
                                setInputProvider={setInputProvider}
                            />
                        </div>
                    </PermissionsGate>
                </div>
            }
        </>
    )
}

export default AddressCompact;