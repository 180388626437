import React, {useState} from 'react';
import ModalAddress from './ModalAddress';
import '../scss/AddressRow.scss';
import {useApi} from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from './PermissionsGate';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import intl from 'react-intl-universal';

const AddressRow = ({ addressId, address1, address2, city, stateProvinceName, zipCode, countryName, editScopes, deleteScopes, disabled, addresses, setAddresses, inputProvider, setInputProvider, className }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [isAddressRowDetailOpen, setIsAddressRowDetailOpen] = useState(false);

    const closeAddressRowDetail = () => setIsAddressRowDetailOpen(false);

    const handleAddressRowDetailOpen = () => {
        setIsAddressRowDetailOpen(true);
    };

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.addressRow.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = await api.addressApi.apiVversionAddressAddressIdDelete(addressId, "1", {}).then((response) => {
                            console.log('address deleted: ' + response);
                            let newAddresses = addresses.filter(address => address.id != addressId);
                            setAddresses(newAddresses);
                            toast.success({ body: intl.get('addressRow.toast.success.handleDelete')});
                        }).catch((error) => {
                            if(error.response) {
                                console.log("Data :" , error.response.data);
                                console.log("Status :" + error.response.status);
                            } else if(error.request) {
                                console.log(error.request);
                            } else {
                                console.log("Error", error.message);
                            }
                            toast.error({ header: intl.get('addressRow.toast.error.handleDelete'), body: error.message });
                        });
                    },
                }
            ]
        });
    };

    if(countryName) countryName = intl.get(countryName)        
        
        return (
        <>
            {
                address1 ?
                <tr className={className}>
                    <td>{address1}</td>
                    <td>{address2}</td>
                    <td>{countryName}</td>
                    <td>{stateProvinceName}</td>
                    <td>{city}</td>
                    <td>{zipCode}</td>
                    <td className='text-center'>
                    <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >
                        <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleAddressRowDetailOpen} title={intl.get('addressRow.editButton.title')}>
                            <HiOutlinePencilAlt/>
                        </a>
                    </PermissionsGate>
                    <ModalAddress isOpen={isAddressRowDetailOpen} closeModal={closeAddressRowDetail} addressId={addressId} addresses={addresses} setAddresses={setAddresses} inputProvider={inputProvider} setInputProvider={setInputProvider}/>
                        <PermissionsGate viewScopes={deleteScopes} editScopes={deleteScopes} viewRoles={[]} editRoles={[]} > 
                            <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleDelete} title={intl.get('addressRow.deleteButton.title')}>
                                <HiOutlineTrash/>
                            </a>
                        </PermissionsGate>
                    </td>
                </tr> :
                null
            }
        </>
    )   
}

export default AddressRow;