import React, { useState, useEffect, FC } from 'react';
import AddressForm from './AddressForm';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateAddressCommand, ListOptionDTO, UpdateAddressCommand } from '../axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { HiOutlineSave } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';
import Spinner from './Spinner';

type AddressProps = {
    addressId: number | null,
    addresses: any,
    setAddresses: any,
    addressesId: any,
    setAddressesId: any,
    closeModal: any,
    inputProvider: any | null,
    setInputProvider: Function | null
};

const Address: FC<AddressProps> = ({ addressId, addresses, setAddresses, addressesId, setAddressesId, closeModal, inputProvider, setInputProvider }) => {

    const defaultAddressInput = {
        address1: '',
        address2: '',
        address3: '',
        idCountry: null,
        country: {name: ''},
        city: '',
        idStateProvince: null,
        stateProvince: {name: ''},
        zipCode: null,
        idAddressType: null,
    };

    const [input, setInput] = useState(defaultAddressInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);

    const [countriesList, setcountriesList] = useState(null)

    const [states, setStates] = useState(null);

    const [addressTypes, setAddresstypes] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchAddress = async (addressId) => {
        if (addressId === null) {
            setInput(defaultAddressInput);
        } else {
            setLoading(true);
            const response = await api.addressApi.apiVversionAddressAddressIdGet(addressId, "1", {}).then((response) => {
                if (response.data) {
                    setInput({
                        address1: response.data?.data?.address1,
                        address2: response.data?.data?.address2,
                        address3: response.data?.data?.address3,
                        idCountry: response.data?.data?.idCountry,
                        country: {name: response.data?.data?.country?.name},
                        city: response.data?.data?.city,
                        idStateProvince: response.data?.data?.idStateProvince,
                        stateProvince: {name: response.data?.data?.stateProvince?.name},
                        zipCode: response.data?.data?.zipCode,
                        idAddressType: response.data?.data?.idAddressType,
                    });
                    setLoading(false);
                };
            }).catch((error) => {
                if(error.response) {
                    console.log("Data :" , error.response.data);
                    console.log("Status :" + error.response.status);
                } else if(error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('address.toast.error.fetchAddress'), body: error.message });
                setLoading(false);
            });
        }
    };

    const fetchCountries = async () => {
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data.data);                
            };
        }).catch((error) => {
            if(error.response) {
                console.log("Data :" , error.response.data);
                console.log("Status :" + error.response.status);
            } else if(error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('address.toast.error.fetchCountries'), body: error.message });
        });
    };

    const fetchStates = async () => {
        if(input.idCountry) {
            const response = await api.addressApi.apiVversionAddressCountryCountryIdStateprovinceAllGet(input.idCountry, "1", {}).then((response) => {
                if (response.data) {
                    setStates(response.data.data);
                };
            }).catch((error) => {
                if(error.response) {
                    console.log("Data :" , error.response.data);
                    console.log("Status :" + error.response.status);
                } else if(error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                toast.error({ header: intl.get('address.toast.error.fetchStates'), body: error.message });
            });
        }
    };

    const fetchListAddressType = async () => {
            setLoading(true);
            await api.listApi.listApiCallBuilderGet("1","addressType","ADDRESS").then((response) => {
                if (response.data.data) {
                    setAddresstypes(response.data.data.queryResult);
                } 
                setLoading(false);
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log("Error", error.message);
                  }
                toast.error({ header: intl.get('jobProject.toast.error.fetchJobsByProject'), body: error.message });
                setLoading(false);
            });
        };

    const fetchListCountries = async () => {
            setLoading(true);
            await api.listApi.listApiCallBuilderGet("1","countries","ADDRESS").then((response) => {
                if (response.data.data) {
                    setcountriesList(response.data.data.queryResult);
                } 
                setLoading(false);
            }).catch((error) => {
                if (error.response) {
                    console.log("Data :", error.response.data);
                    console.log("Status :" + error.response.status);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log("Error", error.message);
                  }
                toast.error({ header: intl.get('jobProject.toast.error.fetchJobsByProject'), body: error.message });
                setLoading(false);
            });
        };

    useEffect(() => {
        fetchAddress(addressId);
        fetchListAddressType();
    }, [addressId]);

    useEffect(() => {
        fetchCountries();
        fetchListCountries();
        if(input.idCountry) {
            fetchStates();
        }
    }, [input.idCountry]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleCountryChange = (e) => {
        const selectedCountry = countries.find(country => country.id == e.id)
        const inputVal = {
            ...input,
            idCountry: e.id,
            country: {...e.country, name: selectedCountry.labelKey},
            idStateProvince: null,
            stateProvince: {name: ''}
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idCountry: e.id
        }));
    };

    const handleStateChange = (e) => {
        const inputVal = {
            ...input,
            idStateProvince: e.id,
            stateProvince: {name: e.name}
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idStateProvince: e.id
        }));
    };

    const handleAddressTypeChange = (e) => {
        const inputVal = {
            ...input,
            idAddressType: e.id,
            addressType: e.name
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idAddressType: e.id
        }));
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.address1) {
            errors.address1 = intl.get('validate.errors.isRequired');
        }
        if (!input.idAddressType) {
            errors.idAddressType = intl.get('validate.errors.isRequired');
        }
        if (!input.idCountry) {
            errors.idCountry = intl.get('validate.errors.isRequired');
        }
        if (!input.city) {
            errors.city = intl.get('validate.errors.isRequired');
        }
        if (!input.idStateProvince) {
            errors.idStateProvince = intl.get('validate.errors.isRequired');
        }
        if (!input.zipCode) {
            errors.zipCode = intl.get('validate.errors.isRequired');
        } else if (input.zipCode < 0) {
            errors.zipCode = intl.get('validate.errors.positive');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleSave = async (e) => {
        setLoading(true);
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (addressId) {
                let cmd: UpdateAddressCommand = {
                    address1: input?.address1,
                    address2: input?.address2,
                    address3: input?.address3,
                    idAddressType: input?.idAddressType,
                    city: input?.city,
                    zipCode: input?.zipCode,
                    idCountry: input?.idCountry,
                    idStateProvince: input?.idStateProvince,
                    id: addressId
                }     
                const response = await api.addressApi.apiVversionAddressAddressPut("1", cmd, {}).then((response) => {
                    let addressEdited = response.data.data;
                    const countryLabel = countriesList.find(country => country.id == addressEdited.idCountry)
                    addressEdited.country = {name: countryLabel.label};
                    addressEdited.stateProvince = {name: input.stateProvince.name};
                    let existingAddresses = addresses.filter(address => address.id != addressId);
                    setAddresses([...existingAddresses, addressEdited]);
                    toast.success({ body: intl.get('address.toast.success.handleSave')});
                    setLoading(false);
                    if (inputProvider !== null) {
                        setInputProvider({...inputProvider, address: cmd})
                    }
                    closeModal();
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('address.toast.error.handleSave'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateAddressCommand = {
                    address1: input?.address1,
                    address2: input?.address2,
                    address3: input?.address3,
                    idAddressType: input?.idAddressType,
                    city: input?.city,
                    zipCode: input?.zipCode,
                    idCountry: input?.idCountry,
                    idStateProvince: input?.idStateProvince
                }
                const response = await api.addressApi.apiVversionAddressAddressPost("1", cmd, {}).then((response) => {
                    let newAddress = response.data.data;
                    newAddress.country = {name: input.country.name};
                    newAddress.stateProvince = {name: input.stateProvince.name};
                    setAddresses([...addresses, newAddress]);
                    const newAddressId = addressesId.includes(newAddress.id);
                    if(!newAddressId) {
                        setAddressesId([...addressesId, newAddress.id]);
                    };
                    toast.success({ body: intl.get('address.toast.success.handleSave.add')});
                    setLoading(false);
                    setInput(defaultAddressInput);
                    if (inputProvider !== null) { 
                        const inputVal = {
                            ...cmd,
                            id: newAddress.id,
                            country: {name: input.country},
                            stateProvince: {name: input.stateProvince}
                        };                    
                        setInputProvider({
                            ...inputProvider, 
                            address: inputVal
                        });
                    }
                    closeModal();
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('address.toast.error.handleSave.add'), body: error.message });
                    setLoading(false);
                });
            };
        }
    };

    const handleCancel = () => {
        fetchAddress(addressId);
        setErrors({});
        closeModal();
    };

    return (
        <div className='container'>
            <div className='col my-3'>
                <h2 className='text-start mb-3'>{addressId ? intl.get('address.title.edit') : intl.get('address.title.add')}</h2>
                <div className="row row-address text-start mb-3">
                    <AddressForm
                        errors={errors}
                        input={input}
                        handleInputChange={handleInputChange}
                        handleStateChange={handleStateChange}
                        handleCountryChange={handleCountryChange}
                        countries={countriesList}
                        selectedCountry={input.country.name}
                        states={states}
                        selectedState={input.stateProvince.name}
                        addressTypes={addressTypes}
                        selectedAddressType={input.idAddressType}
                        handleAddressTypeChange={handleAddressTypeChange}
                    />
                </div>
                <Row>
                    <Col md={4} >
                        <RequiredField/>
                    </Col>
                    <Col md={8} className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {
                            loading === true ?
                            <Spinner small={true} /> :
                            <>
                                <button type="button" className="btn btn-outline-dark" onClick={handleCancel}>{intl.get('cancel.button')}</button>
                                <button type="submit" className="btn btn-primary" onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.address1 === "" ? true : false}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default Address;