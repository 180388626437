import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ClientForm from './ClientForm';
import AddressCompact from './AddressCompact';
import ContactInfoCompact from './ContactInfoCompact';
import ProjectCompact from './ProjectCompact';
import '../scss/ClientDetail.scss';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { CreateClientCommand, UpdateClientCommand } from 'axiosApi/models';
import {PermissionsGate} from '../components/PermissionsGate';
import { SCOPES, ROLES } from "../common/permissions"; 
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { HiArrowLeft, HiOutlineSave, HiPencilAlt } from 'react-icons/hi';
import RequiredField from './RequiredField';
import intl from 'react-intl-universal';
import Spinner from './Spinner';
import { useToastHelper } from 'common/utils';

const ClientDetail = () => {

    const DefaultClientDetailInput = {
        name: '',
        tradeName: '',
        currentPage: 1
    };

    const [input, setInput] = useState(DefaultClientDetailInput);

    const api: Api = useApi();

    const navigate = useNavigate();

    const toast = useToastMessageQueue();
    const toastHelper = useToastHelper(toast);

    const { id } = useParams();
    const idNumber = parseInt(id);

    const [addressesId, setAddressesId] = useState<number[]>([]);

    const [contactInfosId, setContactInfosId] = useState<number[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const fetchClientDetail = async (idNumber: number) => {
        const response = await api.clientApi.apiVversionClientIdGet(idNumber, "1", {}).then((response) => {
            if (response.data) {
                setInput({
                    ...input,
                    name: response.data?.data?.client?.name,
                    tradeName: response.data?.data?.client?.tradeName,
                })
                setAddressesId(response.data?.data?.addressIds);
                setContactInfosId(response.data?.data?.contactInfoIds);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('clientDetail.toast.error.fetchClientDetail'), body: error.message });
        });
    };

    useEffect(() => {
        if (idNumber) {
            fetchClientDetail(idNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
    }, [idNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const validate = function (input) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        if (!input.tradeName) {
            errors.tradeName = intl.get('validate.errors.isRequired');
        }
        return errors;
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (idNumber) {
                let cmd: UpdateClientCommand = {
                    name: input.name,
                    tradeName: input.tradeName,
                    addressesId: addressesId,
                    contactInfosId: contactInfosId,
                    id: idNumber
                };
                const response = await api.clientApi.apiVversionClientPut("1", cmd, {}).then((response) => {
                    if (saveAndEdit)
                        setDisabled(true);
                    else
                        navigate('/clients');
                    toast.success({ body: intl.get('clientDetail.toast.success.handleSubmit')});
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('clientDetail.toast.error.handleSubmit'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateClientCommand = {
                    name: input.name,
                    tradeName: input.tradeName
                };
                const response = await api.clientApi.apiVversionClientPost("1", cmd, {}).then((response) => {
                    toastHelper(response, 'clientDetail.toast.success.handleSubmit.add', 'clientDetail.toast.error.handleSubmit.add');
                    setLoading(false);
                    if (saveAndEdit && "data" in response.data)
                        navigate(`/client/detail/${response.data.data.id}`);
                    else
                        navigate('/clients');
                }).catch((error) => {
                    toastHelper(error, 'clientDetail.toast.success.handleSubmit.add', 'clientDetail.toast.error.handleSubmit.add');
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    setLoading(false);
                });
            };
        };
    };

    const handleBackClick = (e) => {
        navigate('/clients');
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        if (idNumber) {
            fetchClientDetail(idNumber);
        }
        setErrors({});
        setDisabled(!disabled);
    };

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        idNumber ? 
                        <>
                            <h2 className="title">{intl.get('clientDetail.header')}</h2>
                        </> :
                        <h2 className="title">{intl.get('newClient.header')}</h2>
                    }
                </div>
                <div className='card-body'>
                    <ClientForm errors={errors} input={input} handleInputChange={handleInputChange} disabled={disabled} />
                    <RequiredField/>
                    {
                        !!idNumber &&
                        <>
                            <div className='card my-3'>
                                <AddressCompact 
                                    addressesId={addressesId} 
                                    setAddressesId={setAddressesId} 
                                    disabled={disabled} 
                                    editScopes={[SCOPES['clients.edit']]} 
                                    deleteScopes={[SCOPES['clients.edit']]}
                                />
                            </div>
                            <div className='card'>
                                <ContactInfoCompact 
                                    contactInfosId={contactInfosId} 
                                    setContactInfosId={setContactInfosId} 
                                    disabled={disabled} 
                                    editScopes={[SCOPES['clients.edit']]} 
                                    deleteScopes={[SCOPES['clients.edit']]}
                                />
                            </div>
                            <div className='card'>
                                <ProjectCompact 
                                    idNumber={idNumber}
                                    input={input}
                                    setInput={setInput}
                                />
                            </div>
                        </>
                    }
                    <div className='d-flex justify-content-end my-3'>
                        {
                            !idNumber && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                        <PermissionsGate  viewScopes={[SCOPES['clients.edit']]} editScopes={[SCOPES['clients.edit']]} editRoles={[ROLES.clientWriter]} viewRoles={[ROLES.clientReader]}>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                            {/* <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save</button>
                                            <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(true); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save & Edit</button> */}
                                        </PermissionsGate>
                                    </>
                                }
                            </> : null
                        }
                        {
                            idNumber && disabled ?
                            <>
                                <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                    <HiArrowLeft/>
                                </button>
                                <button type='button' className='btn btn-primary me-2' id='edit' onClick={handleEditMode}>   
                                    <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                </button>
                            </> : null
                        }
                        {
                            idNumber && !disabled ?
                            <>
                                <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                    {intl.get('cancel.button')}
                                </button>
                                <PermissionsGate  viewScopes={[SCOPES['clients.edit']]} editScopes={[SCOPES['clients.edit']]} editRoles={[ROLES.clientWriter]} viewRoles={[ROLES.clientReader]}>
                                    <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                    {/* <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save</button>
                                    <button type='button' className='btn btn-primary' id='btn-detail' onClick={(e) => { e.preventDefault(); handleSubmit(true); }} disabled={JSON.stringify(errors) === JSON.stringify({}) && disabled !== true ? false : true}><HiOutlineSave/> Save & Edit</button> */}
                                </PermissionsGate> 
                            </> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientDetail;