import React, { useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../scss/Workflow/WorkflowActionsBar.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import { Button, Spinner } from 'reactstrap';
import { IconPickerItem } from 'react-icons-picker';
import { useWorkflow } from './WorkflowContext';
import WorkflowCommentsModal from './WorkflowCommentsModal';
import {handleAPIError} from '../../common/errorHandler';
export type IEditingModeSetings = {
  edit: boolean,
  view: boolean,
  delete: boolean, 
  editByOwner: boolean,
  viewByOwner: boolean,
  deleteByOwner: boolean
};

export type handleEditingMode = (IEditingModeSetings) => void;
export type WorkflowActionClickParams = (stateId: number, comments?: string) => void;
type WorkflowActionsBarProps = {
  viewMode: string,
  onActionClick: WorkflowActionClickParams,
  loading: boolean,
  id: number,
  handleEditingMode?: handleEditingMode 
};

const WorkflowActionsBar: FC<WorkflowActionsBarProps> = ({ viewMode, onActionClick, loading:parentLoading,  id: stateId, handleEditingMode }) => {

  const { workflow, loading, errors } = useWorkflow();
  const api: Api = useApi();
  const toast = useToastMessageQueue();
  const navigate = useNavigate();

  const [isWorkflowCommentsOpen, setIsWorkflowCommentsOpen] = useState(false);
  const [pendingActionId, setPendingActionId] = useState<number | null>(null);
  
  const closeWorkflowComments = () => {setIsWorkflowCommentsOpen(false)};
  const handleClick = (id: number, showComments:boolean) => {
    
    setIsWorkflowCommentsOpen(showComments);
    setPendingActionId(id);
    if(!showComments || stateId == null)
      onActionClick(id);      
    };
   const onWorkflowCommentsModalSubmitted = (comments: string) => {
      onActionClick(pendingActionId, comments);      
   }
  //console.log('stateID', stateId);
  const handleBackClick = (e) => {
    navigate(-1);
  };

  //when the workflow has loaded, call the parent component to enable or disable the edit, view and delete buttons
  useEffect(() => {
    if(handleEditingMode)
    {
        if(workflow)
        {
            if(stateId == null)
            {
              handleEditingMode({edit: true, view : true, delete : true, editByOwner : true, viewByOwner : true, deleteByOwner : true});
              return;
            }
            var state =  workflow?.states.find(x => x.id == stateId);
            handleEditingMode({edit: state?.editable, view : state?.viewable, delete : state?.deletable, editByOwner : state?.editableByOwner, viewByOwner : state?.viewableByOwner, deleteByOwner : state?.deletableByOwner});
        }
    } 
  }, [workflow]);

  return (
    <div className="workflow-actions-bar">
      {loading && <div className="flex-justify-center"><Spinner color="primary" /></div>}
      {!loading && <>
      <WorkflowCommentsModal
                isOpen={isWorkflowCommentsOpen}
                onCloseModal={closeWorkflowComments}
                onSubmit={onWorkflowCommentsModalSubmitted}
              />
        {viewMode === 'icons' ? (
        workflow?.transitions?.filter(x => (!!stateId?x.workflowStateFrom?.id === stateId: x.workflowStateFrom == null)).sort((a, b) => a.displayOrder - b.displayOrder).map((action, index) => (
          <React.Fragment key={index}>
            <button
              style={{ backgroundColor: action.displayColor ? action.displayColor : '#005BAF' }}
              onClick={() => handleClick(action.idWorkflowStateTo, action.allowComments)} // boolean to be replaced with showComments
              className="icon-button"
              title={action.labelKey ? intl.get(action.labelKey) : action.actionName}
            >
              <IconPickerItem value={action.icon} size={24} color="#ffffff" />
            </button>
          </React.Fragment>
        ))
       ) : (
        <>
          <Button outline={true} type="button" className="btn btn-outline-dark" onClick={handleBackClick}>
            {intl.get('workflow.action.cancel')}
          </Button>
          {workflow?.transitions.filter(x =>(!!stateId?x.workflowStateFrom?.id === stateId: x.workflowStateFrom == null)).sort((a, b) => a.displayOrder - b.displayOrder).map((action, index) => (
            
            <React.Fragment key={index}>
            <Button
              key={index}
              outline={true}
              type="button"
              className="btn-custom"
              style={{ '--button-color': action.displayColor ? action.displayColor : '#005BAF' } as React.CSSProperties}
              onClick={() => handleClick(action.idWorkflowStateTo, action.allowComments)} // boolean to be replaced with showComments
            >
              {action.labelKey ? intl.get(action.labelKey) : action.actionName}
            </Button>
            
            </React.Fragment>
          ))}
        </>
      )}</>}
    </div>
  );
};

export default WorkflowActionsBar;
